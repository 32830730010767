<template>
  <PageTemplate :hideTitle="true">
    <template slot="header-buttons">
    </template>
    <template slot="content">
      <div :class="isMobileView ? '' : 'd-flex'">
        <div :class="isMobileView ? '' : 'me-4 mb-2'" :style="{width: isMobileView ? '100%' : '320px'}">
          <LocationSelection />
          <h5>Filters</h5>
          <div class="card mt-2"  style="height: auto" >
            <div class="card-body">
              <div>
                <label  class="form-label font-w600">Date Range</label>
                <div class="input-group mb-2" style="flex-wrap: nowrap;">
                  <span class="input-group-text "><i class="bi bi-calendar-range"></i></span>
                  <date-range-picker
                    v-model="search.dateRange"
                    opens="right"
                    :showWeekNumbers="false"
                    class="d-block flex-grow-1"
                    :locale-data="locale"
                    @update="updateValues"
                    >
                    <!--Optional scope for the input displaying the dates -->
                    <template v-slot:input="picker">
                      <span v-if="picker.startDate && picker.endDate">{{ picker.startDate | date }} - {{ picker.endDate | date }}</span>
                      <span v-else>All</span>
                    </template>
                  </date-range-picker>
                  <span
                    @click="dateRange.startDate = null, dateRange.endDate = null"
                    class="input-group-text bg-white cursor-pointer"
                    style="border: 1px solid #ccc">
                    <span class="text-danger" ><i class="bi bi-arrow-clockwise" style="font-size: 16px"></i></span>
                  </span>
                </div>
                <div class="mt-4">
                  <label  class="form-label font-w600">Via</label>
                  <div class="d-flex">
                    <div class="form-check me-3">
                      <input class="form-check-input" type="checkbox" id="APPCheck" v-model="search.viaAPP">
                      <label class="form-check-label" for="APPCheck">
                        App
                      </label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" id="RTUCheck" v-model="search.viaRTU">
                      <label class="form-check-label" for="RTUCheck">
                        RTU
                      </label>
                    </div>
                  </div>
                </div>
                <div class="mt-4">
                  <label  class="form-label font-w600">Sort by</label>
                  <div>
                    <label class="radio-inline me-3"><input v-model="search.sort" value="newest" type="radio" /> Newest</label>
                    <label class="radio-inline me-3"><input v-model="search.sort" value="oldest" type="radio" /> Oldest</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-grow-1" :style="{width: isMobileView ? '100%' : 'calc(100% - 300px)', maxWidth: isMobileView ? '100%' : 'calc(100% - 300px)'}">
          <div class="card"  ref="detailsWrapper">
            <div class="card-header" >
              <h4 class="fs-20">Alarm Logs</h4>
            </div>
            <div class="card-body">
              <vue-good-table styleClass="vgt-table bordered"
                mode="remote"
                :totalRows="logInfo ? logInfo.total : 0"
                :columns="tableHeader"
                :rows="dataTable"
                @on-page-change="onPageChange"
                :search-options="{
                  enabled: false,
                  placeholder: 'Search Location Name'
                }"
                :pagination-options="{
                  enabled: true,
                  perPage: 5,
                  perPageDropdownEnabled: false,
                }"
                :theme="theme.themeVersion  === 'dark' ? 'nocturnal' : null ">
                <template slot="table-row" slot-scope="props" v-if="dataTable.length">
                  <span v-if="props.column.field == 'via'">
                    <span style="font-weight: bold" :class="props.row.via == 'RTU' ? 'text-success' : 'text-primary'" >{{props.row.via}}</span>
                  </span>
                  <span v-else-if="props.column.field == 'timestamp'">
                    {{ moment(props.row.timestamp).format('DD/MM/YYYY h:hh a')}}
                  </span>
                  <span v-else>
                    {{props.formattedRow[props.column.field]}}
                  </span>
                </template>
              </vue-good-table>
            </div>
          </div>
        </div>
      </div>
    </template>
  </PageTemplate>
</template>
<script>
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'
import LocationSelection from '@/components/LocationSelection'
import PageTemplate from '@/components/Templates/PageTemplate'
import moment from 'moment'
export default {
  name: 'Logs',
  components: {
    PageTemplate,
    LocationSelection,
    VueGoodTable,
    DateRangePicker
  },
  filters: {
    date (value) {
      if (!value) { return '' }
      // const options = { year: 'numeric', month: 'short', day: 'numeric' }
      return moment(value).format('MM/DD/YYYY')
      // return Intl.DateTimeFormat('en-US', options).format(value)
    }
  },
  data () {
    return {
      locale: {
        direction: 'ltr', // direction of text
        format: 'dd-mm-yyyy', // fomart of the dates displayed
        separator: ' - ', // separator between the two ranges
        applyLabel: 'Apply',
        cancelLabel: 'Cancel',
        weekLabel: 'W',
        customRangeLabel: 'Custom Range',
        /* daysOfWeek: moment.weekdaysMin(), // array of days - see moment documenations for details
        monthNames: moment.monthsShort(), // array of month names - see moment documenations for details */
        firstDay: 1 // ISO first day of week - see moment documenations for details
      },

      search: {
        dateRange: {
          startDate: null,
          endDate: null
        },
        viaRTU: true,
        viaAPP: true,
        sort: 'newest'
      },

      tableHeader: [
        {
          label: 'Location Name',
          field: 'location.name',
          sortable: false
        },
        {
          label: 'Timestamp',
          field: 'timestamp',
          sortable: false
        },
        {
          label: 'Message Log',
          field: 'message',
          sortable: false
        },
        {
          label: 'Via',
          field: 'via',
          sortable: false
        }
      ],
      dataTable: [],
      logInfo: null
    }
  },
  created () {
    this.$emit('pageTitle', 'Logs')
    if (this.$route.query.startDate || this.$route.query.endDate) {
      this.search.dateRange.startDate = new Date(this.$route.query.startDate)
      this.search.dateRange.endDate = new Date(this.$route.query.endDate)
    }
    this.searchLogs(this.search)
  },
  watch: {
    search: {
      deep: true,
      handler (val) {
        console.log(val)
        this.searchLogs(val)
      }
    },
    getSelectedLocation: {
      deep: true,
      handler (val) {
        this.searchLogs(this.search)
      }
    }
  },
  computed: {
    theme () {
      return this.$store.getters['config/theme']
    },
    isMobileView () {
      return this.$store.getters['global/mobileView']
    },
    getSelectedLocation () {
      return this.$store.getters['locations/selectedLocation']
    }
  },
  methods: {
    updateValues () {
      // clear query
      this.$router.push({ query: {} })
    },
    searchLogs (val, page, sameDay) {
      const payload = {
        location_id: this.getSelectedLocation.id,
        sort: val.sort
      }
      if (val.dateRange.startDate !== null || val.dateRange.endDate !== null) {
        payload.start = moment(val.dateRange.startDate).format('YYYY-MM-DD') + ' 00:00:00'
        payload.end = moment(val.dateRange.endDate).format('YYYY-MM-DD') + ' 23:59:59'
      }
      if ((!val.viaRTU && val.viaAPP) || (val.viaRTU && !val.viaAPP)) payload.via = val.viaRTU ? 'RTU' : 'App'
      if (page) payload.url = page

      this.loading(this.$refs.detailsWrapper, 'show')
      this.$store.dispatch('getLogs', payload).then(res => {
        this.dataTable = res.data.logs
        this.logInfo = res.data.pagination
        this.loading(this.$refs.detailsWrapper, 'hide')
      }).catch(err => {
        console.log(err)
        this.$toast.error(err.response.data.error)
        this.loading(this.$refs.detailsWrapper, 'hide')
      })
    },
    onPageChange (params) {
      if (params.currentPage > this.logInfo.current_page) this.searchLogs(this.search, this.logInfo.next_page_url)
      else this.searchLogs(this.search, this.logInfo.prev_page_url)
    }
  }
}
</script>
