/* eslint-disable */
export default {
  namespaced: true,
  state: {
    theme: localStorage.getItem("theme") || null,
    test: 'dsadsdsa'
  },
  mutations: {
    setTheme: (state, payload) => {
      localStorage.setItem('theme', payload);
      state.theme = payload
    },
    clearTheme: (state) => {
      localStorage.removeItem("theme")
      state.theme = null
    },
    c(state, payload) {
      state.test = payload
    }
  },
  actions: {
    test ({ commit }, payload) {
      commit("test", payload);
    },
    changeTheme ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        body.attr({
          'data-theme-version': payload.themeVersion,
          'data-typography': payload.typography,
          'data-layout': payload.layout,
          'data-nav-headerbg': payload.navHeaderbg,
          'data-headerbg': payload.headerbg,
          'data-sibebarbg': payload.sidebarbg,
          'data-sidebar-position': payload.sidebarPosition === "fixed" && payload.sidebarStyle === "overlay" && payload.layout === "vertical" || payload.sidebarStyle === "modern" ? "static" : payload.sidebarPosition,
          'data-header-position': payload.headerPosition,
          'data-container': payload.container,
          'direction': payload.direction,
          'data-primary': payload.primaryColor
        })

        if (payload.layout === "horizontal" && payload.sidebarStyle === "overlay") {
          body.attr("data-sidebar-style", "full");
        } else if (payload.container === "boxed" && payload.layout === "vertical" && payload.sidebarStyle === "full") {
          body.attr("data-sidebar-style", "overlay");
        } else {
          body.attr("data-sidebar-style", payload.sidebarStyle);
        }

        if (payload.sidebarStyle === 'icon-hover') {
          $('.dlabnav').hover(function() {
            $('#main-wrapper').addClass('iconhover-toggle');
          }, function() {
            $('#main-wrapper').removeClass('iconhover-toggle');
          });
        }
        html.attr("dir", "ltr");
        const innerWidth = $(window).innerWidth();
        if (innerWidth < 1200) {
          body.attr("data-layout", "vertical");
          body.attr("data-container", "wide");
        }
        if (innerWidth > 767 && innerWidth < 1200) {
          body.attr("data-sidebar-style", "mini");
        }
        if (innerWidth < 768) {
          body.attr("data-sidebar-style", "overlay");
        }

        commit('setTheme', payload)
        resolve()
      })
    },
    defaultTheme ({ commit }, payload) {
      commit('clearTheme')
    }
  },
  getters: {
    theme(state)  {
      return state.theme
    },
    test(state) {
      return state.test
    }
  }
}
