/* eslint-disable */
export default {
  state: {},
  mutations: {},
  actions: {
    getRTU({ commit }, payload) {
      return new Promise((resolve, reject) => {
        this.axios
          .get("/rtu")
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    getRTUDetails({ commit }, payload) {
      return new Promise((resolve, reject) => {
        this.axios
          .get("/rtu/" + payload)
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            console.log(err);
            reject(err);
          });
      });
    },
    saveRTUDetails({ commit }, payload) {
      const data = {
        serial_no: payload.serial_no,
        ip_address: payload.ip_address,
        phone_no: payload.phone_no,
        location_id: payload.location_id
      };

      return new Promise((resolve, reject) => {
        this.axios
          .put("/rtu/" + payload.id, data)
          .then(res => {
            resolve(res.data.message);
          })
          .catch(err => {
            console.log(err);
            reject(err);
          });
      });
    },
    createRTU({ commit }, payload) {
      return new Promise((resolve, reject) => {
        this.axios
          .post("/rtu", payload)
          .then(res => {
            resolve(res.data.message);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    deleteRTU({ commit }, payload) {
      return new Promise((resolve, reject) => {
        this.axios
          .delete("/rtu/" + payload)
          .then(res => {
            resolve(res.data.message);
          })
          .catch(err => {
            reject(err);
          });
      });
    }
  },
  getters: {}
};
