<template>
  <div v-if="user">
    <div class="card">
      <div class="card-header border-0 flex-wrap align-items-start">
        <div class="col-md-8">
          <div class="user d-sm-flex d-block pe-md-5 pe-0">
            <img :src="user.avatar || '/images/avatar/6.png'" alt="">
            <div class="ms-sm-3 ms-0 me-md-5 md-0">
              <h5 class="mb-1 font-w600"><a href="javascript:void(0);">{{ user.name || '–' }}</a></h5>
              <div class="listline-wrapper mb-2">
                <span class="item"><i class="far fa-envelope"></i>{{ user.email }}</span>
                <span class="item"><i class="far fa-id-badge"></i>{{ user.role | capitalise }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body pt-0">
        <h4 class="fs-20">Description</h4>
        <div class="row">
          <div class="col-xl-6 col-md-6">
            <p class="font-w600 mb-2 d-flex"><span class="custom-label-2">Name : </span><span
                class="font-w400">{{ user.name }}</span></p>
            <p class="font-w600 mb-2 d-flex"><span class="custom-label-2">Role : </span><span
                class="font-w400">{{ user.role | capitalise }}</span></p>
            <p class="font-w600 mb-2 d-flex"><span class="custom-label-2">Email : </span><span
                class="font-w400">{{ user.email }}</span></p>
          </div>
          <div class="col-xl-6 col-md-6">
            <p class="font-w600 mb-2 d-flex"><span class="custom-label-2">Via : </span><span
                class="font-w400">{{ user.via || '–' | capitalise }}</span></p>
            <p class="font-w600 mb-2 d-flex"><span class="custom-label-2">Registered on : </span><span
                class="font-w400">{{ moment(user.created_at).format('DD MMM YYYY') }}</span></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'profile-detail',
  props: {
    user: {
      type: Object,
      default: null
    }
  }
}
</script>
