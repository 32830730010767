<template>
  <div class="nav-header">
    <router-link to="/dashboard" class="brand-logo">
      <img :src="img" style="width: auto; height: 80%; overflow: hidden; border-radius: 10px;" class="" />
      <!-- <div class="app-name d-none d-xl-block"><strong>COCO</strong><br />Fertigation</div> -->
    </router-link>
    <div class="nav-control">
      <div class="hamburger">
        <span class="line"></span><span class="line"></span><span class="line"></span>
      </div>
    </div>
  </div>
</template>
<script>
// import Coco from '../../assets/coco.png'
import CocoPrimary from '../../assets/em.png'
export default {
  name: 'base-nav-header',
  data () {
    return {
      img: CocoPrimary,
      primaryImg: CocoPrimary
    }
  }
}
</script>
<style>
  .app-name {
    font-size: 12px;
    font-weight: 500;
    line-height: 10px;
    margin-left: 3%;
  }
</style>
