<template>
  <div class="row">
    <div class="col-xl-12  col-md-12">
      <div class="mb-2">
        <div class="text-left" v-if="editMode">
          <em class="mb-2 d-block text-black-100">fill in information below than click <span class="mx-1"><i class="fa fa-plus"></i></span> to add new field</em>
        </div>
        <div class="input-group " v-if="editMode">
          <span class="input-group-text bg-primary text-white " style="min-width: 50px"><strong>ADD</strong></span>
          <span class="input-group-text">Field Name</span>
          <input type="text" class="form-control" v-model="form.fieldName" >
          <span class="input-group-text">Field Value</span>
          <input type="text" class="form-control" v-model="form.fieldValue" >
          <span class="input-group-text py-0 bg-primary">
            <button class="btn btn-sm tp-btn btn-rounded text-white" @click="addField" :disabled="!form.fieldName || !form.fieldValue"><i class="fa fa-plus"></i></button>
          </span>
        </div>
      </div>
      <div class="input-group mb-2" :class="!item.name || !item.value ? 'input-danger-o' : ''" v-for="(item, i) in valueF" :key="i + 'dsds'">
        <span class="input-group-text bg-dark text-white" style="min-width: 50px"><strong>{{i + 1}}</strong></span>
        <span class="input-group-text">Field Name</span>
        <input type="type" v-model="item.name" class="form-control" :disabled="!editMode" >
        <span class="input-group-text">Field Value</span>
        <input type="type" v-model="item.value" class="form-control" :disabled="!editMode"  >
        <span class="input-group-text bg-danger py-0" v-if="editMode">
          <button class="btn btn-sm tp-btn btn-rounded text-white" @click="deleteField(i)"><i class="fas fa-times"></i></button>
        </span>
      </div>
      <div v-if="!valueF.length" class="text-center p-3 text-light form-control"><em>No Field Added</em></div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['valueF', 'editMode'],
  data () {
    return {
      form: {
        fieldName: '',
        fieldValue: ''
      }
    }
  },
  methods: {
    addField () {
      const notComplete = Object.values(this.form).some(x => x === null || x === '')
      if (!notComplete) {
        this.$emit('addField', {
          name: this.form.fieldName,
          value: this.form.fieldValue
        })
        this.form.fieldName = ''
        this.form.fieldValue = ''
      }
    },
    deleteField (i) {
      this.$emit('deleteField', i)
    }
  }
}
</script>

<style>

</style>
