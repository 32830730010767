<template>
  <div class="row">
    <div class="col-xl-12  col-md-12 mb-5">
      <div class="mb-2">
        <h4>Telephone No</h4>
        <div class="text-left" v-if="editMode">
          <em class="mb-2 d-block text-black-100">fill in information below than click <span class="mx-1"><i class="fa fa-plus"></i></span> to add new telephone no.</em>
        </div>
        <div class="input-group" v-if="editMode">
          <span class="input-group-text bg-primary text-white " style="min-width: 50px"><strong>ADD</strong></span>
          <span class="input-group-text">Telephone No</span>
          <input type="text" class="form-control" v-model="telephone" >
          <span class="input-group-text py-0 bg-primary">
            <button class="btn btn-sm tp-btn btn-rounded text-white"  @click="addNotication(0)" :disabled="!telephone"><i class="fa fa-plus"></i></button>
          </span>
        </div>
      </div>
      <div class="input-group mb-2" :class="!item ? 'input-danger-o' : ''" v-for="(item, i) in valueN.phones" :key="i + 'dsds'">
        <span class="input-group-text bg-dark text-white" style="min-width: 50px"><strong>{{i + 1}}</strong></span>
        <span class="input-group-text">Telephone No</span>
        <input :disabled="!editMode" type="type" v-model="valueN.phones[i]"  class="form-control" >
        <span class="input-group-text bg-danger py-0" v-if="editMode">
          <button class="btn btn-sm tp-btn btn-rounded text-white" @click="deleteNotication(0, i)"><i class="fas fa-times"></i></button>
        </span>
      </div>
      <div v-if="!valueN.phones.length" class="text-center p-3 text-light form-control"><em>No Telephone Added</em></div>
    </div>
    <div class="col-xl-12  col-md-12">
      <div class="mb-2">
        <h4>Email Address</h4>
        <div class="text-left" v-if="editMode">
          <em class="mb-2 d-block text-black-100">fill in information below than click <span class="mx-1"><i class="fa fa-plus"></i></span> to add new email address</em>
        </div>
        <div class="input-group" v-if="editMode">
          <span class="input-group-text bg-primary text-white " style="min-width: 50px"><strong>ADD</strong></span>
          <span class="input-group-text" :class="emailValid ? '' : 'input-danger-o'">Email Address</span>
          <input type="text" class="form-control" v-model="email" >
          <span class="input-group-text py-0 bg-primary">
            <button class="btn btn-sm tp-btn btn-rounded text-white" @click="addNotication(1)" :disabled="!email"><i class="fa fa-plus"></i></button>
          </span>
        </div>
      </div>
      <div class="input-group mb-2" :class="!item ? 'input-danger-o' : ''" v-for="(item, i) in valueN.emails" :key="i + 'dsds'">
        <span class="input-group-text bg-dark text-white" style="min-width: 50px"><strong>{{i + 1}}</strong></span>
        <span class="input-group-text">Email Address</span>
        <input type="email" v-model="valueN.emails[i]" :disabled="!editMode" class="form-control" >
        <span class="input-group-text bg-danger py-0" v-if="editMode">
          <button class="btn btn-sm tp-btn btn-rounded text-white" @click="deleteNotication(1, i)"><i class="fas fa-times"></i></button>
        </span>
      </div>
      <div v-if="!valueN.emails.length" class="text-center p-3 text-light form-control"><em>No Email Address Added</em></div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['valueN', 'editMode'],
  data () {
    return {
      telephone: '',
      email: '',
      emailValid: true
    }
  },
  watch: {
    email (val) {
      this.validateEmail()
    }
  },
  methods: {
    addNotication (type) {
      if (type === 0) {
        this.$emit('addNoticationTel', this.telephone)
        this.telephone = ''
      } else if (type === 1) {
        this.$emit('addNoticationEmail', this.email)
        this.email = ''
      }
    },
    deleteNotication (type, i) {
      if (type === 0) this.$emit('deleteNoticationTel', i)
      else if (type === 1) this.$emit('deleteNoticationEmail', i)
    },
    test (val) {
      console.log(val)
    },
    validateEmail () {
      var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      if (this.email.match(validRegex)) {
        console.log('Valid email address!')
        this.emailValid = false
      } else {
        console.log('Invalid email address!')
        this.emailValid = true
      }
    }
  }
}
</script>

<style>

</style>
