<template>
  <PageTemplate title="Tariff Config">
     <template slot="header-buttons">
      <template >
        <a
          class="btn btn-primary btn-sm"
          @click="update()"
          :disabled="isLoading"
          ><i class="fas fa-edit me-2 fa-lg"></i>Update</a
        >
      </template>
    </template>
    <template slot="content">
      <div >
        <div class="flex-grow-1 mb-2" >
          <div class="card mb-1"  ref="detailsWrapper">
            <div class="card-body" v-for="(item, i) in tariff" :key="i + 'tariff'">
              <div class="d-lg-flex align-items-center mb-4 mb-lg-2">
                <h3 class="me-2 mb-0">{{item.type === 'on' ? 'Peak Hour - Time' : 'Off-Peak Hour - Time'}}</h3>
                <div class="input-group mb-2 flex-nowrap" style="width: auto">
                  <span class="input-group-text bg-dark text-white " style="min-width: 50px" >From</span>
                  <vue-timepicker placeholder="From" format="HH:mm" class="form-control p-0 border-0" input-class=" timepicker-form-control" v-model="item.from" hour-label="Hour" minute-label="Minute"></vue-timepicker>
                  <span class="input-group-text bg-dark text-white " style="min-width: 50px">To</span>
                  <vue-timepicker placeholder="To" format="HH:mm" class="form-control p-0 border-0" input-class=" timepicker-form-control" v-model="item.to" hour-label="Hour" minute-label="Minute"></vue-timepicker>
                  <span class="input-group-text py-0">
                    <i class="fa fa-clock"></i>
                  </span>
                </div>
              </div>
              <div class="input-group mb-2 d-none d-lg-flex" v-for="(el, index) in item.charges" :key="i + index + 'tariff-list'">
                <span class="input-group-text bg-dark text-white" style="min-width: 50px"><strong>Tariff {{index + 1}}</strong></span>
                <span class="input-group-text"><strong>From</strong></span>
                <input type="number" class="form-control text-end pe-0" v-model="el.from" />
                <span class="input-group-text">
                  kWh
                  <!-- <strong class="ms-3" v-if="item.to == null">and above</strong> -->
                  <strong class="ms-3" >To</strong>
                </span>
                <input type="number" class="form-control text-end pe-0" v-model="el.to" v-if="el.to !== null" />
                <input type="text" disabled class="form-control text-end pe-3" value="and above" v-else />
                <span class="input-group-text border" >kWh</span>
                <span class="input-group-text ">=</span>
                <span class="input-group-text"><strong>Charge RM</strong></span>
                <input type="number" class="form-control ps-2" v-model="el.charge" />
              </div>
              <div class="d-block d-lg-none">
                <template v-for="(el, index) in item.charges">
                  <h5 :key="i + index + 'tariff-f'" >Tariff {{index + 1}}</h5>
                  <div class="input-group mb-1" :key="i + index + 'tariff-x'">
                    <span class="input-group-text"><strong>From</strong></span>
                    <input type="number" class="form-control text-end pe-sm-0" v-model="el.from" />
                    <span class="input-group-text">
                      kWh
                      <strong class="ms-3" v-if="el.to !== null">To</strong>
                      <span  class="ms-1" v-else >and above</span>
                    </span>
                    <input type="number" class="form-control text-end pe-sm-0" v-model="el.to" v-if="el.to !== null" />
                    <span class="input-group-text border" v-if="el.to !== null" >kWh</span>
                  </div>
                  <div class="input-group mb-4"  :key="i + index + 'tariff-e'">
                    <span class="input-group-text"><strong>Charge RM</strong></span>
                    <input type="number" class="form-control ps-2" v-model="el.charge" />
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </PageTemplate>
</template>
<script>
import PageTemplate from '@/components/Templates/PageTemplate'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'

export default {
  name: 'Traiff',
  components: {
    PageTemplate,
    VueTimepicker

  },
  data () {
    return {
      tariff: [],
      time: '',
      isLoading: false
    }
  },
  created () {
    this.$emit('pageTitle', 'Tariff')
    this.getTariff()
  },
  methods: {
    getTariff () {
      this.loading(this.$refs.detailsWrapper, 'show')
      this.$store.dispatch('getTariff').then(res => {
        this.tariff = res.data
        this.loading(this.$refs.detailsWrapper, 'hide')
      })
    },
    update () {
      this.loading(this.$refs.detailsWrapper, 'show')
      this.$store.dispatch('updateTariff', this.tariff).then(res => {
        this.$toast.success(res.data.message)
        this.loading(this.$refs.detailsWrapper, 'hide')
      }).catch(err => {
        console.log(err)
        this.$toast.error('Ops! please try again')
      })
    }
  }
}
</script>
<style>
.custom-input #tj-datetime-input
 {
  display: block;
  width: 100%;
  padding: 0.3125rem 1.25rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #393939;
  background-color: #fff;
  background-repeat: no-repeat !important;
  background-position: right 0.75rem center !important;
  background-size: 16px 12px !important;
  border: 1px solid #ddd;
  border-radius: 0;
  appearance: none;
  height: 3.5rem;
  min-width: auto !important
}
.custom-input-end #tj-datetime-input {
  border-radius: 0 0.75rem 0.75rem 0;
}

</style>
