<template>
  <div class="row">
    <div class="col-md-12 mb-3">Click on <i class="fas fa-check mx-1 text-primary"></i> button to enable the notification</div>
    <div class="col-xl-6 col-lg-12 col-md-12 mb-3">
      <div class="d-md-flex  align-items-center">
        <h4 class="mb-0 me-3" style="min-width: 110px">Temperature</h4>
        <div class="input-group me-2">
          <span class="input-group-text d-flex align-items-center">
            <input type="checkbox" class="form-check-input mt-0 me-2" :disabled="!editMode" v-model="valueTN.TmpTrigMin">
            <span class="mt-1">Min</span>
          </span>
          <input type="number" v-model="valueTN.TmpMin" :disabled="!editMode" placeholder="" class="form-control px-2" >
          <span class="input-group-text d-flex align-items-center">
            <input type="checkbox" class="form-check-input mt-0 me-2" :disabled="!editMode" v-model="valueTN.TmpTrigMax" >
            <span class="mt-1">Max</span>
          </span>
          <input type="number" v-model="valueTN.TmpMax" :disabled="!editMode" placeholder="" class="form-control px-2" >
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-12 col-md-12 mb-3">
      <div class="d-md-flex align-items-center">
        <h4 class="mb-0 me-3" style="min-width: 110px">Humidity</h4>
        <div class="input-group me-2">
          <span class="input-group-text d-flex align-items-center">
            <input type="checkbox" class="form-check-input mt-0 me-2" :disabled="!editMode" v-model="valueTN.HumTrigMin">
            <span class="mt-1">Min</span>
          </span>
          <input type="number" v-model="valueTN.HumMin" :disabled="!editMode" placeholder="" class="form-control px-2" >
          <span class="input-group-text d-flex align-items-center">
            <input type="checkbox" class="form-check-input mt-0 me-2" :disabled="!editMode" v-model="valueTN.HumTrigMax" >
            <span class="mt-1">Max</span>
          </span>
          <input type="number" v-model="valueTN.HumMax" :disabled="!editMode" placeholder="" class="form-control px-2" >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['valueTN', 'editMode'],
  data () {
    return {}
  }
}
</script>
