<template>
  <div>
    <Toolbar>
      <button @click="isEdit = !isEdit, closeAlert()" class="btn btn-primary btn-sm me-3" title="Edit">
        <i v-if="!isEdit" class="fas fa-edit"></i>
        <i v-else class="fas fa-arrow-circle-left"></i>
      </button>
    </Toolbar>
    <div class="row" ref="profileCont">
      <div class="col-xs-12">
        <Alert :type="'danger'" v-if="error" @close="closeAlert">
          <template v-slot:title>Error</template>
          <template v-slot:content>
            <ol class="ol-num">
              <template v-for="field in error">
                <template v-for="errs in field">
                  <li v-for="err in errs" :key="err">{{ err }}</li>
                </template>
              </template>
            </ol>
          </template>
        </Alert>
        <ProfileDetail v-if="!isEdit" :user="user"></ProfileDetail>
        <ProfileEdit v-if="isEdit" :user="user" @save="save"></ProfileEdit>
      </div>
    </div>
  </div>
</template>

<script>
import Toolbar from '@/components/Base/Toolbar'
import Alert from '@/components/Alert'
import ProfileDetail from '@/components/Profile/Detail'
import ProfileEdit from '@/components/Profile/Edit'
export default {
  name: 'profile',
  data: () => {
    return {
      isEdit: false,
      user: null,
      error: null
    }
  },
  components: {
    Toolbar, Alert, ProfileDetail, ProfileEdit
  },
  mounted () {
    /* Page Title */
    this.$emit('pageTitle', 'Profile')

    this.getProfile()
  },
  methods: {
    getProfile () {
      this.loading(this.$refs.profileCont, 'show')
      this.axios.get('/user').then(response => {
        this.user = response.data
        this.loading(this.$refs.profileCont, 'hide')
      }).catch(err => {
        console.error(err)
        this.loading(this.$refs.profileCont, 'hide')
      })
    },
    save (data) {
      this.loading(this.$refs.profileCont, 'show')
      this.axios.put('/user', data).then(response => {
        this.user = response.data.user
        this.loading(this.$refs.profileCont, 'hide')
        this.$toast.success(response.data.message)
        this.isEdit = false
      }).catch(err => {
        this.error = err.response.data
        this.loading(this.$refs.profileCont, 'hide')
      })
    },
    closeAlert () {
      this.error = null
    }
  }
}
</script>
