<template>
  <div>
    <router-link to="/dashboard" class="btn btn-primary">Back to dashboard</router-link>
  </div>
</template>

<script>
export default {
  name: 'blank',
  mounted () {
    /* Page Title */
    this.$emit('pageTitle', 'Blank')
  }
}
</script>
