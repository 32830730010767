<template>
  <PageTemplate :hideTitle="true">
    <template slot="content">
      <!-- <div>
        <em>
          <small class="d-none d-xl-block text-end mb-2" style="margin-top: -30px">Refresh in {{countdown}} seconds</small>
          <span  class="d-block d-xl-none text-center mb-2" style="margin-top: -15px">Refresh in {{countdown}} seconds</span>
        </em>
      </div> -->
      <div :class="isMobileView ? '' : 'd-flex'">
        <div
          :class="isMobileView ? '' : 'me-4'"
          :style="{ width: isMobileView ? '100%' : '320px' }"
        >
          <LocationSelection />
          <div class="card" style="height: auto">
            <div class="card-body p-3">
              <label class="form-label font-w600">Appliance</label>
              <select class="form-select form-control" v-model="search.selectedApp">
                <option :value="null">All</option>
                <option
                  v-for="(list, index) in appliances"
                  :value="list.id"
                  :key="index"
                  >{{ list.name }}</option
                >
              </select>
              <div class="mt-4">
                <label  class="form-label font-w600" >Start Month</label>
                <div class="input-group mb-2"  style="flex-wrap: nowrap;">
                  <span class="input-group-text "><i class="bi bi-calendar"></i></span>
                  <VueDatePicker
                    noHeader
                    no-calendar-icon
                    class="custom-datepicker form-control"
                    :type="'month'"
                    :format="'YYYY-MM'"
                    placeholder=""
                    v-model="search.startMonth" />
                  <span
                    @click="search.startMonth = null"
                    class="input-group-text bg-white cursor-pointer"
                    style="border: 1px solid #ccc">
                    <span class="text-danger" ><i class="bi bi-arrow-clockwise" style="font-size: 16px"></i></span>
                  </span>
                </div>
              </div>
              <div class="mt-4">
                <label  class="form-label font-w600" >End Month</label>
                <div class="input-group mb-2"  style="flex-wrap: nowrap;">
                  <span class="input-group-text "><i class="bi bi-calendar"></i></span>
                  <VueDatePicker
                    noHeader
                    no-calendar-icon
                    class="custom-datepicker form-control"
                    :type="'month'"
                    :format="'YYYY-MM'"
                    placeholder=""
                    v-model="search.endMonth" />
                  <span
                    @click="search.endMonth = null"
                    class="input-group-text bg-white cursor-pointer"
                    style="border: 1px solid #ccc">
                    <span class="text-danger" ><i class="bi bi-arrow-clockwise" style="font-size: 16px"></i></span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-grow-1" :style="{width: isMobileView ? '100%' : 'calc(100% - 300px)', maxWidth: isMobileView ? '100%' : 'calc(100% - 300px)'}">
          <div class="card"  ref="detailsWrapper">
            <div class="card-header" >
              <h4 class="fs-20">Total Consumption & Estimation</h4>
            </div>
            <div class="card-body">
              <BarChart :chartData="chartData" :chartLabels="chartLabels" :options="options" v-if="search.startMonth || search.endMonth" label="Consumption" />
              <div v-else class="text-center align-items-center justify-content-center d-flex m-auto" style="height: 100%">
                <em class="mb-5">{{'No Data Available. Please select year'}}</em>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </PageTemplate>
</template>
<script>
// import Dialog from '@/components/Templates/Dialog'
import PageTemplate from '@/components/Templates/PageTemplate'
import LocationSelection from '@/components/LocationSelection'
/* import Chart from '@/components/Templates/ChartSensor'
import ChartLine from '@/components/Templates/ChartLine' */
import { VueDatePicker } from '@mathieustan/vue-datepicker'
import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css'
import BarChart from '@/components/Templates/ChartBar'
import moment from 'moment'
export default {
  name: 'Sensor',
  components: {
    /* Dialog, */
    PageTemplate,
    LocationSelection,
    /* Chart,
    ChartLine, */
    VueDatePicker,
    BarChart
  },
  data () {
    return {
      appliances: [],
      isLoading: false,

      chartData: [],
      chartLabels: [],
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            ticks: {
              beginAtZero: true
            }
          }
        },
        onClick: this.checkDetails,
        plugins: {
          tooltip: {
            callbacks: {
              footer: this.footer
            }
          }
        }
      },

      // interval Id
      intervalId: '',
      countdown: 0,
      countdownIntervalId: '',

      // filters
      search: {
        startMonth: moment(new Date()).add(-1, 'months').format('YYYY-MM'),
        endMonth: moment(new Date()).format('YYYY-MM'),
        selectedApp: null
      },
      raw: []
    }
  },
  created () {
    this.$emit('pageTitle', 'Bill')
  },
  computed: {
    theme () {
      return this.$store.getters['config/theme']
    },
    isMobileView () {
      return this.$store.getters['global/mobileView']
    },
    getSelectedLocation () {
      return this.$store.getters['locations/selectedLocation']
    }
  },
  watch: {
    getSelectedLocation: {
      deep: true,
      handler (val) {
        this.search.selectedApp = null
        this.init()
      }
    },
    search: {
      deep: true,
      handler (val) {
        console.log(val)
        if (val.startMonth !== null || val.endMonth !== null) {
          if (val.startMonth === val.endMonth) val.endMonth = moment(val.endMonth, 'YYYY-MM').add(1, 'months').format('YYYY-MM')
          if (val.endMonth === val.startMonth) val.startMonth = moment(val.endMonth, 'YYYY-MM').add(-1, 'months').format('YYYY-MM')
          clearInterval(this.intervalId)
          this.intervalId = setTimeout(() => {
            this.searchBill()
          }, 2000)
        }
      }
    }
  },
  methods: {
    countDown () {
      clearInterval(this.countdownIntervalId)
      this.countdown = this.$config.apiRequestInterval / 1000
      this.countdownIntervalId = setInterval(
        function () {
          this.countdown = this.countdown - 1
        }.bind(this),
        1000
      )
    },
    getAppliance () {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch('getAppliances', this.getSelectedLocation.id)
          .then(res => {
            this.appliances = res.data
            resolve(this.appliances)
          })
          .catch(err => {
            reject(err.response.data.error)
            this.$toast.error(err.response.data.error)
          })
      })
    },
    searchBill () {
      return new Promise((resolve, reject) => {
        const payload = {
          locationId: this.getSelectedLocation.id,
          params: {
            start_month: moment(this.search.startMonth).format('YYYY-MM'),
            end_month: moment(this.search.endMonth).format('YYYY-MM')
          }
        }
        if (this.search.selectedApp !== null) payload.params.appliance_id = this.search.selectedApp

        this.chartData.splice(0, this.chartData.length)
        this.chartLabels.splice(0, this.chartLabels.length)
        this.loading(this.$refs.detailsWrapper, 'show')
        this.$store.dispatch('getBillData', payload).then(res => {
          this.raw = res.data
          res.data.forEach((item) => {
            console.log(item)
            this.chartData.push(item.total_consumption)
            this.chartLabels.push(item.monthYear)
            this.loading(this.$refs.detailsWrapper, 'hide')
          })
          resolve(res)
        })
      })
    },
    footer (val) {
      /* let sum = '' */
      // console.log(val, this.raw)
      /* this.raw.forEach(el => {
        sum = el.total_estimated
      }) */
      return 'Estimation: RM' + this.raw[val[0].dataIndex].total_estimated
    },
    async init () {
      await this.getAppliance()
      await this.searchBill()
    }
  }
}
</script>
<style>
.jobs {
  transition: ease all 0.3s;
}
</style>
