<template>
  <div>
    <div class="row">
      <div class="col-xl-12  col-md-12">
        <div v-for="(item, i) in valueP" :key="i + 'main'" class="mb-3">
          <div class="row">
            <div class="col-md-12" >
              <div class="d-flex align-items-center justify-content-between">
                <h4>Pump {{item.no}}</h4>
              </div>
            </div>
            <div class="col-12">
              <label class="radio-inline me-3">
                <input type="radio" :disabled="!editMode" value="1" class="form-check-input mt-0 me-2" v-model="item.mode" />
                Manual Mode
              </label>
              <label class="radio-inline me-3">
                <input type="radio" :disabled="!editMode" value="2" class="form-check-input mt-0 me-2" v-model="item.mode" />
                Time Mode
              </label>
              <label class="radio-inline me-3">
                <input type="radio" :disabled="!editMode" value="3" class="form-check-input mt-0 me-2" v-model="item.mode" />
                Sensor Mode
              </label>
            </div>
            <div class="col-12">
              <div class="form-check form-switch mb-2 mt-2" v-if="item.mode == '1'">
                <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" :disabled="!editMode" v-model="item.manual_irrigation" />
                <label class="form-check-label" for="flexSwitchCheckChecked">Manual Irrigation <strong>{{ item.manual_irrigation ? 'ON' : 'OFF'}}</strong></label>
              </div>
            </div>
            <div class="col-12">
              <div class="input-group mb-4" v-if="item.mode == '2'">
                <span class="input-group-text d-flex align-items-center justify-content-start flex-fill">
                  <input type="checkbox" :disabled="!editMode" class="form-check-input mt-0 me-2" v-model="item.days.monday" >
                  <span class="mt-1">Monday</span>
                </span>
                <span class="input-group-text d-flex align-items-center justify-content-start flex-fill">
                  <input type="checkbox" :disabled="!editMode" class="form-check-input mt-0 me-2" v-model="item.days.tuesday" >
                  <span class="mt-1">Tuesday</span>
                </span>
                <span class="input-group-text d-flex align-items-center justify-content-start flex-fill">
                  <input type="checkbox" :disabled="!editMode" class="form-check-input mt-0 me-2" v-model="item.days.wednesday" >
                  <span class="mt-1">Wednesday</span>
                </span>
                <span class="input-group-text d-flex align-items-center justify-content-start flex-fill">
                  <input type="checkbox" :disabled="!editMode" class="form-check-input mt-0 me-2" v-model="item.days.thursday" >
                  <span class="mt-1">Thursday</span>
                </span>
                <span class="input-group-text d-flex align-items-center justify-content-start flex-fill">
                  <input type="checkbox" :disabled="!editMode" class="form-check-input mt-0 me-2" v-model="item.days.friday" >
                  <span class="mt-1">Friday</span>
                </span>
                <span class="input-group-text d-flex align-items-center justify-content-start flex-fill">
                  <input type="checkbox" :disabled="!editMode" class="form-check-input mt-0 me-2" v-model="item.days.saturday" >
                  <span class="mt-1">Saturday</span>
                </span>
                <span class="input-group-text d-flex align-items-center justify-content-start flex-fill">
                  <input type="checkbox" :disabled="!editMode" class="form-check-input mt-0 me-2" v-model="item.days.sunday" >
                  <span class="mt-1">Sunday</span>
                </span>
              </div>
            </div>
            <div class="col-12 mb-4"  v-if="item.mode == '2'">
              <em class="mb-2 d-block text-black-100" v-if="editMode">fill in information below than click <span class="mx-1"><i class="fa fa-plus"></i></span> to add new time range</em>
              <div class="input-group mb-2" v-if="editMode" >
                <span class="input-group-text bg-primary text-white " style="min-width: 50px"><strong>ADD</strong></span>
                <span class="input-group-text"><strong>Time On</strong></span>
                <vue-timepicker placeholder="Time On" format="h:mm A" class="form-control p-0 border-0" input-class=" timepicker-form-control" v-model="form[i].time_on"></vue-timepicker>
                <span class="input-group-text"><strong>Time Off</strong></span>
                <vue-timepicker placeholder="Time Off" format="h:mm A" class="form-control p-0 border-0" input-class=" timepicker-form-control" v-model="form[i].time_off"></vue-timepicker>
                <span class="input-group-text py-0 bg-primary">
                  <button class="btn btn-sm tp-btn btn-rounded text-white" @click="addTime(i)" :disabled="!form[i].time_on || !form[i].time_off"><i class="fa fa-plus"></i></button>
                </span>
              </div>
              <div class="input-group mb-2" v-for="(el, index) in item.times" :key="index + 'sub'">
                <span class="input-group-text bg-dark text-white" style="min-width: 50px"><strong>{{index + 1}}</strong></span>
                <span class="input-group-text"><strong>Time On</strong></span>
                <vue-timepicker :disabled="!editMode" placeholder="Time On" format="h:mm A" class="form-control p-0 border-0" input-class=" timepicker-form-control" v-model="el.time_on"></vue-timepicker>
                <span class="input-group-text"><strong>Time Off</strong></span>
                <vue-timepicker :disabled="!editMode" placeholder="Time Off" format="h:mm A" class="form-control p-0 border-0" input-class=" timepicker-form-control" v-model="el.time_off"></vue-timepicker>
                <span class="input-group-text bg-danger py-0" v-if="editMode">
                  <button class="btn btn-sm tp-btn btn-rounded text-white" @click="deleteTime(i, index)"><i class="fas fa-times"></i></button>
                </span>
              </div>
              <div v-if="!item.times.length" class="text-center p-3 text-light form-control"><em>No Time Added</em></div>
            </div>
            <div class="col-12 mb-4">
              <div class="input-group mb-2" v-if="item.mode == '3'">
                <span class="input-group-text">Turn<strong class="mx-1">ON</strong>when moister less than</span>
                <input type="number" v-model="item.sensor.moisture_less_than" :disabled="!editMode" placeholder="" class="form-control px-2" />
                <span class="input-group-text">Turn<strong class="mx-1">OFF</strong>when moisture greater than</span>
                <input type="number" v-model="item.sensor.moisture_greater_than" :disabled="!editMode" placeholder="" class="form-control px-2" />
              </div>
            </div>
            <hr />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
export default {
  props: ['valueP', 'editMode'],
  components: {
    VueTimepicker
  },
  data () {
    return {
      form: [
        {
          time_on: '12:00 AM',
          time_off: '12:00 AM'
        },
        {
          time_on: '12:00 AM',
          time_off: '12:00 AM'
        },
        {
          time_on: '12:00 AM',
          time_off: '12:00 AM'
        }
      ]
    }
  },
  computed: {
    theme () {
      return this.$store.getters['config/theme']
    },
    isMobileView () {
      return this.$store.getters['global/mobileView']
    }
  },
  watch: {
  },
  created () {
    // console.log(this.valueP)
  },
  methods: {
    addTime (i) {
      const notComplete = Object.values(this.form).some(x => x === null || x === '')
      if (!notComplete) {
        this.$emit('addTime', {
          index: i,
          data: {
            time_on: this.form[i].time_on,
            time_off: this.form[i].time_off
          }
        })
        this.form[i].time_on = '12:00 AM'
        this.form[i].time_off = '12:00 AM'
      }
    },
    deleteTime (i, index) {
      this.$emit('deleteTime', { no: i, index: index })
    },
    updateManualIrrigation (id, value, index) {
      const payload = {
        id: id,
        manual: value
      }
      this.$store.dispatch('locations/switchIrrigation', payload).then(res => {
        this.$toast.success('Manual irrigigation successfully updated')
      }).catch(err => {
        this.$toast.error(err.response.data.error)
        this.valueP[index].status = false
        console.log(this.valueP[index].status)
        console.log(err)
      })
    }
  }
}
</script>

<style>
.timepicker-form-control {
  background: #fff !important;
  border: 0.0625rem solid #dddddd !important;
  padding: 0.3125rem 1.25rem !important;
  color: #6e6e6e;
  height: 100% !important;
  border-radius: 0;
  width: 100% !important;
}
</style>
