<template>
  <PageTemplate title="Location Listing" >
    <template slot="header-buttons">
      <div class="dropdown custom-dropdown mb-0" v-if="isMobileView" >
        <div class="btn sharp tp-btn dark-btn" data-bs-toggle="dropdown">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="#342E59" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z" stroke="#342E59" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z" stroke="#342E59" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
        <div class="dropdown-menu dropdown-menu-right">
          <a class="dropdown-item" @click="changePage('/settings/locations/create')" ><i class="fas fa-save me-2"></i>CREATE NEW</a>
        </div>
      </div>
      <a class="btn btn-primary btn-sm" @click="changePage('/settings/locations/create')" v-else><i class="fas fa-plus me-2"></i>CREATE NEW</a>
    </template>
    <template slot="content">
      <vue-good-table styleClass="vgt-table bordered"
          @on-row-click="onRowClick"
          :columns="tableHeader"
          :rows="dataTable"
          :search-options="{
            enabled: true,
            placeholder: 'Search Location Name'
          }"
          :pagination-options="{
            enabled: true,
            mode: 'pages',
            perPage: 5
          }"
          :theme="theme.themeVersion  === 'dark' ? 'nocturnal' : null ">
        </vue-good-table>
    </template>
  </PageTemplate>
</template>
<script>
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'
import PageTemplate from '@/components/Templates/PageTemplate'
export default {
  name: 'RTU',
  components: {
    VueGoodTable,
    PageTemplate
  },
  data () {
    return {
      tableHeader: [
        {
          label: 'Name',
          field: 'name'
        },
        {
          label: 'Address',
          field: 'address'
        }
      ],
      dataTable: []
    }
  },
  computed: {
    theme () {
      return this.$store.getters['config/theme']
    },
    isMobileView () {
      return this.$store.getters['global/mobileView']
    }
  },
  created () {
    this.$emit('pageTitle', 'Locations')
    this.$store.dispatch('locations/getLocations').then(res => {
      this.dataTable = res.data
    })
  },
  methods: {
    onRowClick (val) {
      this.$router.push('/settings/locations/' + val.row.id)
    }
  }
}
</script>
