<template>
  <div class="row">
    <div class="col-xl-12  col-md-12  py-2">
      <label  class="form-label font-w600">Name<span class="text-danger scale5 ms-2">*</span></label>
      <input :disabled="!editMode" type="text" class="form-control"  v-model="valueG.name" />
    </div>
    <div class="col-xl-12  col-md-12  py-3">
      <label  class="form-label font-w600">Address<span class="text-danger scale5 ms-2">*</span></label>
      <textarea rows="3" :disabled="!editMode" type="text" class="form-control" v-model="valueG.address" />
    </div>
    <div class="col-xl-12  col-md-12  py-3">
      <label  class="form-label font-w600">Coordinate<span class="text-danger scale5 ms-2">*</span></label>
      <div class="input-group">
        <span class="input-group-text" :class="valueG.lat ? '' : 'input-danger-o'">Lat</span>
        <input type="number" :disabled="!editMode" class="form-control" v-model="valueG.lat" @input="checkLat(valueG.lat)" @keypress="checkKey"  >
        <span class="input-group-text" :class="valueG.lng ? '' : 'input-danger-o'">Lng</span>
        <input type="number" :disabled="!editMode" class="form-control" v-model="valueG.lng" @input="checkLng(valueG.lng)" @keypress="checkKey" >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['valueG', 'editMode'],
  data () {
    return {
    }
  },
  methods: {
    checkLat (val) {
      if (val < -90) this.valueG.lat = -90
      else if (val > 90) this.valueG.lat = 90
    },
    checkLng (val) {
      if (val < -180) this.valueG.lng = -180
      else if (val > 180) this.valueG.lng = 180
    },
    checkKey (e) {
      /* var keycode = (e.keyCode ? e.keyCode : e.which)
      if (/-?\D/.test(String.fromCharCode(keycode))) { // a non–digit was entered
        e.preventDefault()
      } */
    }
  }
}
</script>

<style>

</style>
