<template>
  <div class="container h-100">
    <div class="row justify-content-center h-100 align-items-center">
      <div class="col-md-7">
        <div class="form-input-content text-center error-page">
          <h1 class="error-text fw-bold">404</h1>
          <h4><i class="fa fa-exclamation-triangle text-warning"></i> The page you were looking for is not found!</h4>
          <p>You may have mistyped the address or the page may have moved.</p>
          <div>
            <button v-if="isLoggedIn" @click="redirectTo('dashboard')" class="btn btn-primary">Back to dashboard</button>
            <button v-else @click="redirectTo('login')" class="btn btn-primary">Back to login</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'error-404',
  computed: {
    isLoggedIn () {
      return this.$store.getters['user/isLoggedIn']
    }
  },
  mounted () {
    /* Page Title */
    this.$emit('pageTitle', '')
  },
  methods: {
    redirectTo (pageName) {
      /* location.href = page */
      this.$router.push({ name: pageName })
    }
  }
}
</script>
