<template>
  <div class="row">
    <div class="col-xl-6 col-lg-12 col-md-12 mb-3">
      <div class="d-md-flex align-items-center">
        <h4 class="mb-0 me-3" style="min-width: 110px">Moisture</h4>
        <div class="input-group me-2">
          <span class="input-group-text">Factor</span>
          <input type="number" v-model="valueC.Moisture" placeholder="" :disabled="!editMode" class="form-control px-2" >
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-12 col-md-12 mb-3">
      <div class="d-md-flex align-items-center">
        <h4 class="mb-0 me-3" style="min-width: 110px">Temperature</h4>
        <div class="input-group me-2">
          <span class="input-group-text">Factor</span>
          <input type="number" v-model="valueC.Temperature" placeholder="" :disabled="!editMode" class="form-control px-2" >
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-12 col-md-12 mb-3">
      <div class="d-md-flex align-items-center">
        <h4 class="mb-0 me-3" style="min-width: 110px">EC</h4>
        <div class="input-group me-2">
          <span class="input-group-text">Factor</span>
          <input type="number" v-model="valueC.EC" placeholder="" :disabled="!editMode" class="form-control px-2" >
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-12 col-md-12 mb-3">
      <div class="d-md-flex align-items-center">
        <h4 class="mb-0 me-3" style="min-width: 110px">pH</h4>
        <div class="input-group me-2">
          <span class="input-group-text">Factor</span>
          <input type="number" v-model="valueC.pH" placeholder="" :disabled="!editMode" class="form-control px-2" >
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-12 col-md-12 mb-3">
      <div class="d-md-flex align-items-center">
        <h4 class="mb-0 me-3" style="min-width: 110px">N</h4>
        <div class="input-group me-2">
          <span class="input-group-text">Factor</span>
          <input type="number" v-model="valueC.N" placeholder="" :disabled="!editMode" class="form-control px-2" >
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-12 col-md-12 mb-3">
      <div class="d-md-flex align-items-center">
        <h4 class="mb-0 me-3" style="min-width: 110px">P</h4>
        <div class="input-group me-2">
          <span class="input-group-text">Factor</span>
          <input type="number" v-model="valueC.P" placeholder="" :disabled="!editMode" class="form-control px-2" >
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-12 col-md-12 mb-3">
      <div class="d-md-flex align-items-center">
        <h4 class="mb-0 me-3" style="min-width: 110px">K</h4>
        <div class="input-group me-2">
          <span class="input-group-text">Factor</span>
          <input type="number" v-model="valueC.K" placeholder="" :disabled="!editMode" class="form-control px-2" >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['valueC', 'editMode'],
  data () {
    return {}
  }
}
</script>
