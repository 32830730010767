<template>
  <div class="stepper-wrapper" :class="isMobileView ? 'stepper-mobile' : 'stepper-desktop d-stiky'" v-if="steppers.length" >
    <div :class="isMobileView ? '' : 'd-stiky'">
      <h5 class="mb-4">Step / Form</h5>
      <div class="stepper-item d-flex align-items-center pb-4"
        :class="[
          item.statusCompleted ? 'stepper-completed' : '',
          $route.query.stepperActive == i ? 'stepper-active' : '',
          checkDisabled(i) ? 'stepper-disabled' : ''
          ]"
          v-for="(item, i) in steppers" :key="i + item.component"
          @click="changeStepper(i, item.statusCompleted, checkDisabled(i))" >
        <div class="stepper-no"><strong>{{i + 1}}</strong></div>
        <div class="stepper-label ps-3">
          <h5 class=" mb-0">{{item.label}}</h5>
          <em
            v-if="steppers[i].statusCompleted || parseInt($route.query.stepperActive) == i || (i > 0 && steppers[i - 1].statusCompleted)"
            :style="{ color: item.statusCompleted ? 'rgba(140, 169, 72)' : 'var(--primary)'}">
            {{ item.statusCompleted ? 'Completed' : 'In Progress' }}
          </em>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['steppers'],
  data () {
    return {
    }
  },
  watch: {
    steppers: {
      deep: true,
      handler (value) {
        // if (value[].statusCompleted)
      }
    }
  },
  computed: {
    theme () {
      return this.$store.getters['config/theme']
    },
    isMobileView () {
      return this.$store.getters['global/mobileView']
    }
  },
  methods: {
    changeStepper (i, status, disabled) {
      if (parseInt(this.$route.query.stepperActive) !== i && !disabled) {
        if ((i > 0 && this.steppers[i - 1].statusCompleted === true) || status === true) {
          this.$router.push({
            query: { stepperActive: i }
          })
        }
      }
    },
    checkDisabled (max) {
      if (max > 0) {
        let test = false
        this.steppers.forEach((e, i) => {
          if (i < max && test === false && e.statusCompleted === false) test = true
        })
        return test
      }
    }
  }
}
</script>
<style>
.stepper-mobile { width: 100% }
.stepper-desktop { width: 300px; }
.stepper-item {
  position: relative;
  transition: ease all 0.3s;
  padding-left: 10px; padding-right: 10px
}
.stepper-item:before {
  content: '';
  width: 100%;
  height: calc(100% - 2px);
  position: absolute;
  top: -9px;
  left: -30px;
  background-color: #e9e9e9;
  border-radius: 30px;
  z-index: -1;
  opacity: 0;
  transition: ease all 0.3s;
}
[data-theme-version="dark"] .stepper-item:before {
  background-color: #212130;
}
.stepper-item:not(.stepper-disabled):hover:before,
.stepper-item.stepper-active:before {
  opacity: 1;
  left: 0px;
}
.stepper-item:after {
  content: '';
  position: absolute;
  left: 29px;
  height: calc(100% - 40px);
  bottom: 0;
  width: 2px;
  background-color: #ccc;
  transition: ease all 0.3s;
}
.stepper-item:last-child:after{
  display: none;
}
.stepper-no {
  width: 40px;
  height: 40px;
  text-align: center;
  vertical-align: middle;
  border: #ccc solid 2px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0px;
  position: relative;
  z-index: 1;
  background-color: white;
  transition: ease all 0.3s;
}
[data-theme-version="dark"] .stepper-no {
  background-color: #212130;
  border: rgba(204, 204, 204, 0.3) solid 2px;
}
.stepper-active .stepper-no {
  border: var(--primary) solid 2px  !important;
}
.stepper-item .stepper-label {
  position: relative;
  z-index: 1;
}
.stepper-completed .stepper-no {
  border: #a7c957 solid 2px;
}
.stepper-completed.stepper-item:after {
  background-color: #a7c957
}
.stepper-completed .stepper-no {
  background-color: #a7c957;
  color: black
}
.stepper-item.stepper-active,
.stepper-item.stepper-completed {
  opacity: 1;
}
.stepper-item.stepper-disabled { opacity: 0.5 !important; cursor: not-allowed; }
.stepper-item:not(.stepper-disabled) { cursor: pointer }

</style>
