<template>
  <PageTemplate title="Search Report">
    <template slot="content">
      <div :class="isMobileView ? '' : '' ">
        <div class="flex-grow-1 mb-2" >
          <div class="card mb-1"  ref="detailsWrapper">
            <div class="card-header">
              <div class="row flex-grow-1">
                <div class="col-sm-6 col-md-3">
                  <select class="form-select form-control mb-2" v-model="search.location_id">
                    <option :selected="search.location_id == null ? true : false" :value="null">Choose Location</option>
                    <option v-for="list in locations" :value="list.id" :key="list.id">{{list.name}}</option>
                  </select>
                </div>
                <div class="col-sm-6 col-md-3">
                  <div class="input-group mb-2" style="flex-wrap: nowrap;">
                    <span class="input-group-text "><i class="bi bi-calendar-range"></i></span>
                    <date-range-picker
                      v-model="search.dateRange"
                      opens="right"
                      :showWeekNumbers="false"
                      class="d-block flex-grow-1"
                      :locale-data="locale"
                      >
                      <!--Optional scope for the input displaying the dates -->
                      <template v-slot:input="picker">
                        <span v-if="picker.startDate && picker.endDate">{{ picker.startDate | date }} - {{ picker.endDate | date }}</span>
                        <span v-else>Select Date Range</span>
                      </template>
                    </date-range-picker>
                    <span
                      @click="search.dateRange.startDate = null, search.dateRange.endDate = null"
                      class="input-group-text bg-white cursor-pointer"
                      style="border: 1px solid #ccc">
                      <span class="text-danger" ><i class="bi bi-arrow-clockwise" style="font-size: 16px"></i></span>
                    </span>
                  </div>
                </div>
                <div class="col-sm-6 col-md-3">
                  <select class="form-select form-control mb-2" v-model="search.type">
                    <option :selected="search.type == null ? true : false" :value="null">Choose report type</option>
                    <option value="appliance" >Appliance</option>
                    <option value="variable" >Variable</option>
                    <option value="pc">Power Consumption</option>
                  </select>
                </div>
                <div class="col-sm-6 col-md-3" v-if="search.type === 'appliance' || search.type === 'pc'">
                  <select class="form-select form-control mb-2" v-model="appliance" :disabled="isLoading">
                    <option :selected="search.type == null ? true : false" :value="null">Choose appliance</option>
                    <option v-for="(item, i) in appliances" :value="item.id" :key="i + 'appl'" >{{item.name}}</option>
                  </select>
                </div>
                <div class="col-sm-12 col-md-4 text-center text-md-start" v-if="search.type">
                  <button class="btn btn-primary me-2" @click="getReport" :disabled="!search.dateRange.startDate || !search.location_id">Get Report</button>
                  <vue-excel-xlsx
                    v-if="dataTable.length"
                    :data="dataTable"
                    :columns="tableHeader"
                    :file-name="'Report_'+ activereportLocation + '_' + moment(dataInfo.start_date).format('DD-MM-YYYYY') + '-' + moment(dataInfo.end_date).format('DD-MM-YYYYY')"
                    :file-type="'xlsx'"
                    :sheet-name="'report'"
                    class="btn light btn-primary"
                    >
                    Download
                  </vue-excel-xlsx>
                </div>
              </div>
              <!-- <div class="mb-3">
                <label  class="form-label font-w600">Minutes Gap</label>
                <select class="form-select form-control" v-model="search.minute_gap">
                  <option :selected="search.minute_gap == null ? true : false" :value="null">Choose...</option>
                  <option v-for="list in intervals" :value="list.value" :key="list.value">{{list.text}}</option>
                </select>
              </div> -->
            </div>
            <div class="card-body" >
              <vue-good-table styleClass="vgt-table bordered"
                v-if="dataTable.length"
                :totalRows="dataTable.length"
                :columns="tableHeader"
                :rows="dataTable"
                :search-options="{
                  enabled: false,
                  placeholder: 'Search Location Name'
                }"
                :sort-options="{ enabled: false }"
                :pagination-options="{
                  enabled: true,
                  perPage: 5,
                  perPageDropdownEnabled: false,
                }"
                :theme="theme.themeVersion  === 'dark' ? 'nocturnal' : null ">
                <!-- <template slot="table-row" slot-scope="props" v-if="dataTable.length">
                  <span v-if="props.column.field == 'via'">
                    <span style="font-weight: bold" :class="props.row.via == 'RTU' ? 'text-success' : 'text-primary'" >{{props.row.via}}</span>
                  </span>
                  <span v-else-if="props.column.field == 'timestamp'">
                    {{ moment(props.row.timestamp).format('DD/MM/YYYY h:hh a')}}
                  </span>
                  <span v-else>
                    {{props.formattedRow[props.column.field]}}
                  </span>
                </template> -->
              </vue-good-table>
              <div v-else class="text-center align-items-center justify-content-center d-flex m-auto" style="max-width: 300px; height: 100%">
                <em class="mb-5">No record found.</em>
              </div>
            </div>

          </div>
        </div>
      </div>
    </template>
  </PageTemplate>
</template>
<script>
import PageTemplate from '@/components/Templates/PageTemplate'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'
import moment from 'moment'
export default {
  name: 'Reports',
  components: {
    PageTemplate,
    DateRangePicker,
    VueGoodTable
  },
  data () {
    return {
      isLoading: false,
      locale: {
        direction: 'ltr', // direction of text
        format: 'dd-mm-yyyy', // fomart of the dates displayed
        separator: ' - ', // separator between the two ranges
        applyLabel: 'Apply',
        cancelLabel: 'Cancel',
        weekLabel: 'W',
        customRangeLabel: 'Custom Range',
        /* daysOfWeek: moment.weekdaysMin(), // array of days - see moment documenations for details
        monthNames: moment.monthsShort(), // array of month names - see moment documenations for details */
        firstDay: 1 // ISO first day of week - see moment documenations for details
      },
      search: {
        dateRange: {
          startDate: null,
          endDate: null
        },
        location_id: null,
        minute_gap: null,
        type: null
      },
      appliance: null,
      appliances: [],
      locations: [],
      intervals: [
        { text: '5 minutes', value: 5 },
        { text: '15 minutes', value: 15 },
        { text: '1 hour', value: 60 }
      ],

      tableHeader: [],
      dataTable: [],
      dataInfo: null,
      activereportLocation: ''
    }
  },
  filters: {
    date (value) {
      if (!value) { return '' }
      // const options = { year: 'numeric', month: 'short', day: 'numeric' }
      return moment(value).format('MM/DD/YYYY')
      // return Intl.DateTimeFormat('en-US', options).format(value)
    }
  },
  created () {
    this.$emit('pageTitle', 'Report')
    this.init()
  },
  computed: {
    theme () {
      return this.$store.getters['config/theme']
    },
    isMobileView () {
      return this.$store.getters['global/mobileView']
    },
    getSelectedLocation () {
      return this.$store.getters['locations/selectedLocation']
    }
  },
  watch: {
    'search.location_id': {
      deep: true,
      handler (val) {
        if (val === 'variable') this.appliance = null
        this.getAppliance()
      }
    }
  },
  methods: {
    getAppliance () {
      this.isLoading = true
      return new Promise((resolve, reject) => {
        this.$store.dispatch('getAppliances', this.search.location_id).then(res => {
          this.appliances = res.data
          this.appliance = null
          this.isLoading = false
          resolve(this.appliances)
        }).catch(err => {
          reject(err.response.data.error)
          this.$toast.error(err.response.data.error)
          this.isLoading = false
        })
      })
    },
    getReport () {
      const payload = {
        data: {}
      }
      if (this.search.type === 'appliance') payload.url = '/locations/' + this.search.location_id + '/reports/appliances/' + this.appliance + '/variables'
      if (this.search.type === 'pc') payload.url = '/locations/' + this.search.location_id + '/reports/appliances/' + this.appliance + '/power-consumption'
      else if (this.search.type === 'variable') payload.url = '/locations/' + this.search.location_id + '/reports/variables'

      if (this.search.dateRange.startDate !== null || this.search.dateRange.endDate !== null) {
        payload.data.start_date = moment(this.search.dateRange.startDate).format('YYYY-MM-DD') + ' 00:00:00'
        payload.data.end_date = moment(this.search.dateRange.endDate).format('YYYY-MM-DD') + ' 23:59:59'
      }
      this.$store.dispatch('getReportData', payload).then(res => {
        this.dataTable = res.data.data
        this.tableHeader = res.data.columns
        this.activereportLocation = this.locations.find(x => x.id === this.search.location_id).name
        this.dataInfo = payload
      }).catch(err => {
        console.log(err.response)
        this.$toast.error(err.response.data.message)
      })
    },
    init () {
      this.search.location_id = this.getSelectedLocation.id
      this.$store.dispatch('locations/getLocations').then(res => {
        this.locations = res.data
      })
    }
  }

}
</script>
<style>

</style>
