var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PageTemplate',{attrs:{"title":"RTU Listing"}},[_c('template',{slot:"header-buttons"},[(_vm.isMobileView)?_c('div',{staticClass:"dropdown custom-dropdown mb-0"},[_c('div',{staticClass:"btn sharp tp-btn dark-btn",attrs:{"data-bs-toggle":"dropdown"}},[_c('svg',{attrs:{"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z","stroke":"#342E59","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z","stroke":"#342E59","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z","stroke":"#342E59","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})])]),_c('div',{staticClass:"dropdown-menu dropdown-menu-right"},[_c('a',{staticClass:"dropdown-item",on:{"click":function($event){return _vm.changePage('/settings/rtu/create')}}},[_c('i',{staticClass:"fas fa-save me-2"}),_vm._v("CREATE NEW")])])]):_c('a',{staticClass:"btn btn-primary btn-sm",on:{"click":function($event){return _vm.changePage('/settings/rtu/create')}}},[_c('i',{staticClass:"fas fa-plus me-2"}),_vm._v("CREATE NEW")])]),_c('template',{slot:"content"},[_c('vue-good-table',{attrs:{"styleClass":"vgt-table bordered","columns":_vm.tableHeader,"rows":_vm.dataTable,"search-options":{
          enabled: true,
          placeholder: 'Search serial no'
        },"pagination-options":{
          enabled: true,
          mode: 'pages',
          perPage: 5
        },"theme":_vm.theme.themeVersion  === 'dark' ? 'nocturnal' : null},on:{"on-row-click":_vm.onRowClick}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }