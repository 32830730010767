<template>
  <GmapMap
    :center="{ lat: 4.2105, lng: 101.9758 }"
    :zoom="7"
    :options="options"
  >
    <GmapMarker
      :key="index"
      v-for="(m, index) in markers"
      :position="m.position"
      @click="center = m.position"
    />
    <gmap-info-window
      @closeclick="window_open=false"
      :opened="window_open"
      :position="infowindow"
      :options="{
        pixelOffset: {
          width: 0,
          height: -35
        }
      }"
    >
    <div v-if="locationInfo">
      <h4 style="color: black !important">{{locationInfo.name}}</h4>
      <div>{{locationInfo.lat}}° N, {{locationInfo.lng}}° E</div>
    </div>
    </gmap-info-window>
  </GmapMap>
</template>

<script>

export default {
  props: ['locations', 'trigger'],
  data () {
    return {
      refreshing: false,
      markers: [
      ],
      options: {
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        disableDefaultUI: false
      },
      constumeStyles: [
        {
          elementType: 'geometry',
          stylers: [{ color: '#242f3e' }]
        },
        {
          elementType: 'labels.text.fill',
          stylers: [{ color: '#746855' }]
        },
        {
          elementType: 'labels.text.stroke',
          stylers: [{ color: '#242f3e' }]
        },
        {
          featureType: 'administrative.locality',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#d59563' }]
        },
        {
          featureType: 'poi',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#d59563' }]
        },
        {
          featureType: 'poi.park',
          elementType: 'geometry',
          stylers: [{ color: '#263c3f' }]
        },
        {
          featureType: 'poi.park',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#6b9a76' }]
        },
        {
          featureType: 'road',
          elementType: 'geometry',
          stylers: [{ color: '#38414e' }]
        },
        {
          featureType: 'road',
          elementType: 'geometry.stroke',
          stylers: [{ color: '#212a37' }]
        },
        {
          featureType: 'road',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#9ca5b3' }]
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry',
          stylers: [{ color: '#746855' }]
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.stroke',
          stylers: [{ color: '#1f2835' }]
        },
        {
          featureType: 'road.highway',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#f3d19c' }]
        },
        {
          featureType: 'transit',
          elementType: 'geometry',
          stylers: [{ color: '#2f3948' }]
        },
        {
          featureType: 'transit.station',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#d59563' }]
        },
        {
          featureType: 'water',
          elementType: 'geometry',
          stylers: [{ color: '#17263c' }]
        },
        {
          featureType: 'water',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#515c6d' }]
        },
        {
          featureType: 'water',
          elementType: 'labels.text.stroke',
          stylers: [{ color: '#17263c' }]
        }
      ],
      defaultStyles: [],
      info_marker: null,
      infowindow: null,
      window_open: false,
      locationInfo: null
    }
  },
  mounted () {
  },
  computed: {
    theme () {
      return this.$store.getters['config/theme']
    }
  },
  watch: {
    trigger (val) {
      if (val !== null) {
        this.window_open = true
        this.infowindow = { lat: parseFloat(val.lat), lng: parseFloat(val.lng) }
        this.locationInfo = val
      } else {
        this.window_open = false
        this.infowindow = { lat: 0, lng: 0 }
        this.locationInfo = null
      }
    },
    locations: {
      deep: true,
      immediate: true,
      handler (val) {
        const newMarker = []
        val.forEach(item => {
          const position = { lat: parseFloat(item.lat), lng: parseFloat(item.lng) }
          newMarker.push({
            position: position
          })
        })
        this.markers = newMarker
      }
    },
    theme: {
      immediate: true,
      deep: true,
      handler  (val) {
      /*  if (val.themeVersion === 'dark') {
          console.log(val.themeVersion)
          this.options.styles = this.constumeStyles
          this.$gmapDefaultResizeBus.$emit('resize')
        } else {
        } */
        // this.refreshing = false
      }
    }
  },
  methods: {
    openWindow () {
      this.window_open = true
    }
  }
}
</script>
