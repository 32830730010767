<template>
  <div class="alert alert-dismissible left-icon-big fade show" :class="'alert-' + type">
    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="btn-close" @click="close()">
        <span><i class="mdi mdi-btn-close"></i></span>
    </button>
    <div class="media">
        <div class="alert-left-icon-big">
            <span>
                <i :class="{
                    'fas fa-info': type === 'secondary',
                    'fas fa-exclamation-triangle': type === 'danger',
                    'far fa-thumbs-up': type === 'success'
                }">
                </i>
            </span>
        </div>
        <div class="media-body">
            <h6 class="mt-1 mb-2 font-weight-bold"><slot name="title"></slot></h6>
            <p class="mb-0"><slot name="content"></slot></p>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'alert',
  props: {
    type: {
      type: String,
      default: 'secondary'
    }
  },
  methods: {
    close () {
      this.$emit('close', true)
    }
  }
}
</script>

<style scoped>
.font-weight-bold {
    font-weight: 600;
}
</style>
