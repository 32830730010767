<template>
  <div class="container h-100">
    <div class="row justify-content-center h-100 align-items-center">
      <div class="col-md-6">
        <div class="authincation-content">
          <div class="row no-gutters">
            <div class="col-xl-12">
              <div class="auth-form">
                <div class="text-center mb-3">
                  <router-link to="/" class="logo-wrapper">
                    <img :src="img" alt="" style="width: 180px;">
                    <!-- <div class="app-name"><strong>COCO</strong><br />Fertigation</div> -->
                  </router-link>
                </div>
                <template v-if="!isMessage">
                  <template v-if="isLoggedIn">
                    <h4 class="text-center mb-4">Validating...</h4>
                    <div class="text-center">
                      <a style="pointer: cursor;" class="btn btn-primary btn-block"><i class="fas fa-spinner fa-spin"></i></a>
                    </div>
                  </template>
                  <template v-else>
                    <h4 class="text-center mb-4" style="color: #a1a1a1">Sign in or register your account</h4>
                    <div class="text-center">
                      <a :href="$config.apiUrl + '/auth/google/'" class="btn btn-primary btn-block">
                        <i class="fab fa-google mr"></i>
                        Sign In with Google
                      </a>
                    </div>
                  </template>
                </template>
                <template v-else>
                  <h4 class="text-center mb-4">{{ message }}</h4>
                  <div class="text-center">
                    <button @click="clearMessage"  class="btn btn-primary btn-block">
                      Back to login
                    </button>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Coco from '../assets/EM-FULL_03.png'
export default {
  name: 'Login',
  data: () => {
    return {
      isMessage: false,
      message: null,
      img: Coco
    }
  },
  created () {
  },
  computed: {
    isLoggedIn () {
      return this.$store.getters['user/isLoggedIn']
    },
    theme () {
      return this.$store.getters['config/theme']
    }
  },
  watch: {
  },
  mounted () {
    if (typeof this.$route.params.error !== 'undefined') {
      this.$toast.error(this.$route.params.error)
    }

    if (typeof this.$route.query.msg !== 'undefined') {
      this.isMessage = true
      this.message = this.$route.query.msg
      this.$router.push(this.$route.path)
    }
  },
  methods: {
    clearMessage () {
      this.isMessage = false
      this.message = null
    }
  }
}
</script>
<style scoped>
.btn {
  padding: 0.7rem 1.5rem;
}
.mr {
  margin-right: 1rem;
}
.app-name {
  font-size: 20px;
  font-weight: 500;
  line-height: 18px;
  margin-left: 3%;
}
.logo-wrapper {
  display: flex;
  text-align: left;
  justify-content: center;
  align-items: center;
}
</style>
