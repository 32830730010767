<template>
  <PageTemplate title="Playground">
    <template slot="header-buttons">
      Test
    </template>
    <template slot="content">
      Test
    </template>
  </PageTemplate>
</template>
<script>
import PageTemplate from '@/components/Templates/PageTemplate'
export default {
  name: 'Playground',
  components: {
    PageTemplate
  },
  data () {
    return {}
  },
  created () {
    this.$emit('pageTitle', 'Playground')
  }
}
</script>
